<template>
  <div class="event-single">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ event.name }}
          </h1>
          <h2 class="subtitle ">
            <strong>Date:</strong> {{ event.date }}
            <br>
            <strong>Time:</strong> {{ event.time }}
          </h2>
        </div>
      </div>
    </section>
    <section class="event-content">
      <div class="container">
        <p class="is-size-4 description">{{ event.description }}</p>
        <p class="is-size-5"><strong>Platform:</strong> {{ event.platform }}</p>
        <p class="is-size-5"><strong>Category:</strong> {{ event.category }}</p>
        <div class="event-images columns is-multiline has-text-centered">
          <div v-for="image in event.images" :key="image.id" class="column is-one-third">
            <img :src="image" :alt="event.name"/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'AppSingle',
  data () {
    return {
      event: {},
      events: [
        {
          id: 1,
          name: 'Stock Post',
          category: 'Finance',
          description: 'web tool to show stock information',
          featuredImage: 'https://placekitten.com/500/500',
          images: [
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
          ],
          platform: 'windows',
          date: '2020-10-08',
          time: '11:30'
        },
        {
          id: 2,
          name: 'URL shortener',
          category: 'Tools',
          description: 'Shorten long url for easy transfer',
          featuredImage: 'https://placekitten.com/500/500',
          images: [
            'https://placekitten.com/500/500'
          ],
          platform: 'All',
          date: '2021-08-09',
          time: '12:00'
        },
        {
          id: 3,
          name: 'Private Credential',
          category: 'Security',
          description: 'web tool to show account credentials',
          featuredImage: 'https://placekitten.com/500/500',
          images: [
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
            'https://placekitten.com/500/500',
          ],
          platform: 'all',
          date: '2021-09-09',
          time: '11:30'
        }
      ]
    }
  },
  mounted() {
    const ID = Number(this.$route.params.id)
    let event = this.events.find(event => event.id === ID)
    this.event = event
  }
}
</script>
<style lang="scss" scoped>
.event-single {
  margin-top: 30px;
}
.hero {
  margin-bottom: 70px;
}
.event-images {
  margin-top: 50px;
}
.description {
  margin-bottom: 30px;
}
</style>